import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Weather } from './weather';


@Injectable({
  providedIn: 'root'
})
export class WeatherService {

    private APIURL = 'https://pawelwnuk.pl/api/weather.php';  // URL to web api


  constructor(private http: HttpClient) { }

    getWeather(func, adr, loc, par, time): Observable<Weather[]> {
        const URL = `${this.APIURL}?func=${func}&adr=${adr}&loc=${loc}&par=${par}&time=${time}`;
        return this.http.get<Weather[]>(`${URL}`);
    }
}
