import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homebrewing',
  templateUrl: './homebrewing.component.html',
  styleUrls: ['./homebrewing.component.sass']
})
export class HomebrewingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  goToLink(url: string) {
    window.open(url, '_blank');
  }
}
