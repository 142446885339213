import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Landing page components
import { LandingPageComponent } from './landing-page/landing-page.component';
import { WebdesignComponent } from './landing-page/webdesign/webdesign.component';
import { HomebrewingComponent } from './landing-page/homebrewing/homebrewing.component';
import { ElectronicsComponent } from './landing-page/electronics/electronics.component';

import { CookieService } from 'ngx-cookie-service';
import { CookiesInfoComponent } from './cookies-info/cookies-info.component';

// import { WeatherService} from './weather.service'

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    WebdesignComponent,
    HomebrewingComponent,
    ElectronicsComponent,
    CookiesInfoComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    NgbCarouselModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule
  ],
  providers: [CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }
