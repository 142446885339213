import { Component, OnInit } from '@angular/core';
import { Weather } from '../../weather';
import { WeatherService } from '../../weather.service';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-electronics',
  templateUrl: './electronics.component.html',
  styleUrls: ['./electronics.component.sass']
})
export class ElectronicsComponent implements OnInit {
  latestWeather$: Observable<Weather[]>;

  constructor(private weatherService: WeatherService) { }

  ngOnInit() {
    this.latestWeather$ = this.getLatestWeather('latest', 'BONI', 'outside', '', 5);
  }

  getLatestWeather(func, adr, loc, par, time): Observable<Weather[]> {
    return this.weatherService.getWeather(func, adr, loc, par, time);
  }

  goToLink(url: string) {
    window.open(url, '_blank');
  }
}
