import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-cookies-info',
  templateUrl: './cookies-info.component.html',
  styleUrls: ['./cookies-info.component.sass']
})
export class CookiesInfoComponent implements OnInit {
  
  cookieExists: boolean;
  cookieValue: string;

  constructor(private cookieService: CookieService) { 
    //this.cookieService.set( 'Test', 'Hello World' );
    this.cookieService.delete( 'Test' );

    this.cookieExists = cookieService.check('Test');
    this.cookieValue = this.cookieService.get('Test');

   }

  ngOnInit() {
  }

}
