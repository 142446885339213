import {
  AfterViewInit,
  Component,
  ElementRef,
  ViewChildren,
} from "@angular/core";
import { LandingPageComponent } from "./landing-page/landing-page.component";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.sass"],
})
export class AppComponent implements AfterViewInit {
  title = "pawelwnuk";
  version = "0.1.2";

  home: HTMLElement;
  webdev: HTMLElement;
  electronics: HTMLElement;
  brew: HTMLElement;
  contact: HTMLElement;

  @ViewChildren(LandingPageComponent, {
    read: ElementRef,
  })
  private landingPageComponent: ElementRef<HTMLElement>;

  ngAfterViewInit(): void {
    console.log(this.landingPageComponent.nativeElement);
    this.home = this.landingPageComponent.nativeElement.querySelector("#home");
    this.webdev =
      this.landingPageComponent.nativeElement.querySelector("#webdev");
    this.electronics =
      this.landingPageComponent.nativeElement.querySelector("#electronics");
    this.brew = this.landingPageComponent.nativeElement.querySelector("#brew");
    this.contact =
      this.landingPageComponent.nativeElement.querySelector("#contact");
    this.contact =
      this.landingPageComponent.nativeElement.querySelector("#contact");
  }

  scrollToElement($element): void {
    $element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }
}
